// import "bootstrap";
import $ from 'jquery'
import 'jquery-ui-dist/jquery-ui';
import 'jquery.ripples';
// import Swiper from 'swiper';


function msieversion() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
    {
        return true
    }
    return false;
}

var ie = msieversion();
if (ie){
    $('body').addClass('iexplorer');
}

if ($("#home-ripple-bg").length > 0){
    $("#home-ripple-bg").ripples({
        perturbance: 0.01,
        dropRadius: 20
    });
}
$(".sog-menu").on("click", function (){
    $(".sog-modal").addClass('active');
    return false;
})
$(".sog-modal a").on("click", function (){
    $(".modal").removeClass('active');
})
$(".rules-menu").on("click", function (){
    $(".rules-modal").addClass('active');
    return false;
})
$(".rules-modal a").on("click", function (){
    $(".modal").removeClass('active');
})

$("#burger").on("click", function (){
    $("#main-menu").addClass("active");
    if (ie){
        $("#burger").hide();
    }
})
$("#close-menu").on("click", function (){
    $("#main-menu").removeClass("active");
    if (ie){
        $("#burger").show();
    }
})
$(".no-dcotor-btn").on("click", function (){
    $(".for-doctor-modal").hide();
    $(".not-doctor").show();
    return false;
})
$(".is-doctor-btn").on("click", function (){
    $(".modal").removeClass('active');
})
$(".back-nodoctor").on("click", function (){
    $(".for-doctor-modal").show();
    $(".not-doctor").hide();
    return false;
})
$("#save-btn").on("click", function (){
    $(".cert-form").addClass('active');
    return false;
})
$(".back-modal").on("click", function (){
    $(".modal").removeClass('active');
})
$(".send-pdf").on("click", function (){
    $(".modal-form-input").removeClass('error');
    if ($("#input-surname").val() == ''){
        $("#input-surname").parent().addClass('error');
    }
    if ($("#input-name").val() == ''){
        $("#input-name").parent().addClass('error');
    }
    if ($(".modal-form-input.error").length == 0){
        window.location = 'cert.php?name='+$("#input-name").val()+'&surname='+$("#input-surname").val()+'&lastname='+$("#input-lastname").val();
    }
    // $(".modal").removeClass('active');
})
$('.modal-form-input input').on('input', function() {
    if ($(this).val() != ''){
        $(this).parent().addClass('fill').removeClass('error');
    }else{
        $(this).parent().removeClass('error fill');
    }
});

if ($(".text-block.with-scroll").length > 0){
    var start_drag = false;
    $(".text-block-content").on('scroll', function (){
        if (!start_drag){
            var scroll = $(this).scrollTop();
            var koef_h = $(".text-block-content-data").height() - $(".text-block-content").height();
            var koef_sh = $(".text-block-content").height() - 63;
            var scroll_koef = scroll / koef_h;
            $(this).parents('.text-block').find(".text-block-scrollbar-thumb").css({"top": koef_sh*scroll_koef});
        }
    })

    $(".text-block-scrollbar-thumb").draggable({
        containment: "parent",
        start: function( event, ui ) {
            start_drag = true;
        },
        drag: function( event, ui ) {
            var scroll = $(this).position().top;
            var koef_h = $(".text-block-content-data").height() - $(".text-block-content").height();
            var koef_sh = $(".text-block-content").height() - 63;
            var scroll_koef = scroll / koef_sh;
            $(this).parents('.text-block').find(".text-block-content").scrollTop(koef_h*scroll_koef);
        },
        stop: function( event, ui ) {
            start_drag = false;
        }
    })
}

var show_more = false;
$(".show-more").on("click", function (){
    show_more = !show_more;
    if (show_more){
        $(this).html("Скрыть")
        $(".show-more-content").show();
    }else{
        $(this).html("Узнать больше")
        $(".show-more-content").hide();
    }
});

if ($("#question").length > 0){
    $("#left-btn").hover(function (){
        $(".question-content-front").addClass('left-rotation');
    }, function (){
        $(".question-content-front").removeClass('left-rotation');
    });
    $("#right-btn").hover(function (){
        $(".question-content-front").addClass('right-rotation');
    }, function (){
        $(".question-content-front").removeClass('right-rotation');
    });

    $("#left-btn").on('click', function (){
        $("#left-btn").css({"visibility":"hidden"});
        $("#right-btn").css({"visibility":"hidden"});
        $(".question-content-front").removeClass('left-rotation');
        var rnd = Math.floor(Math.random()*3.8);
        if ($(this).hasClass("good")){
            $(".question-content-good").addClass('active left-rotation')
            $(".question-content-good .question-content-good-title span").hide();
            $(".question-content-good .question-content-good-title span").eq(rnd).show();
            $(".question-content-good .question-content-good-text-item").hide();
            $(".question-content-good .question-content-good-text-item").eq(rnd).show();
            setTimeout(function (){
                if (document.body.clientWidth <= 700){
                    $(".question-content-good .yellow-btn").click();
                }
            }, 3000)
        }else{
            $(".question-content-bad").addClass('active left-rotation')
            $(".question-content-bad .question-content-bad-title span").hide();
            $(".question-content-bad .question-content-bad-title span").eq(rnd).show();
            $(".question-content-bad .question-content-bad-text-item").hide();
            $(".question-content-bad .question-content-bad-text-item").eq(rnd).show();
            setTimeout(function (){
                if (document.body.clientWidth <= 700){
                    $(".question-content-bad .yellow-btn").click();
                }
            }, 3000)
        }
    });
    $("#right-btn").on('click', function (){
        $("#left-btn").css({"visibility":"hidden"});
        $("#right-btn").css({"visibility":"hidden"});
        $(".question-content-front").removeClass('right-rotation');
        var rnd = Math.floor(Math.random()*3.8);
        if ($(this).hasClass("good")){
            $(".question-content-good").addClass('active right-rotation')
            $(".question-content-good .question-content-good-title span").hide();
            $(".question-content-good .question-content-good-title span").eq(rnd).show();
            $(".question-content-good .question-content-good-text-item").hide();
            $(".question-content-good .question-content-good-text-item").eq(rnd).show();
            setTimeout(function (){
                if (document.body.clientWidth <= 700){
                    $(".question-content-good .yellow-btn").click();
                }
            }, 3000)
        }else{
            $(".question-content-bad").addClass('active right-rotation')
            $(".question-content-bad .question-content-bad-title span").hide();
            $(".question-content-bad .question-content-bad-title span").eq(rnd).show();
            $(".question-content-bad .question-content-bad-text-item").hide();
            $(".question-content-bad .question-content-bad-text-item").eq(rnd).show();
            setTimeout(function (){
                if (document.body.clientWidth <= 700){
                    $(".question-content-bad .yellow-btn").click();
                }
            }, 3000)
        }
    });

    $(".question-content-bad .yellow-btn").on("click", function (){
        var link = $(this).attr("href");
        if ($(".question-content-bad").hasClass("right-rotation")){
            $(".question-content-bad").addClass("full-right-rotation")
            $(".question-content-front").addClass("full-right-rotation")
        }else{
            $(".question-content-bad").addClass("full-left-rotation")
            $(".question-content-front").addClass("full-left-rotation")
        }

        setTimeout(function (){
            window.location = link
        },400);
        return false;
    });
    $(".question-content-good .yellow-btn").on("click", function (){
        var link = $(this).attr("href");
        if ($(".question-content-good").hasClass("right-rotation")){
            $(".question-content-good").addClass("full-right-rotation")
            $(".question-content-front").addClass("full-right-rotation")
        }else{
            $(".question-content-good").addClass("full-left-rotation")
            $(".question-content-front").addClass("full-left-rotation")
        }
        setTimeout(function (){
            window.location = link
        },400);
        return false;
    });
}


// alert($("#rotate-device").width() + "x" + $("#rotate-device").height());
